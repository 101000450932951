import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Home from "./view/Home";
import LogoutBtn from "./components/LogoutBtn/LogoutBtn";
import Login from "./view/Login";
import Register from "./view/Register";
import OtpVerify from "./view/OtpVerify";
import Rewards from "./view/Rewards";
import Upload from "./view/Upload";
import { useDispatch } from "react-redux";
import { userLogout } from "./redux/user/userActions";
import LandingPage from "./view/LandingPage";
import AuthRoute from "./components/AuthRoute/AuthRoute";
import Submissions from "./view/Submissions";
import ShowStayTunedHaleonRewardsModalMaybe from "./components/StayTunedHaleonRewardsModal/StayTunedHaleonRewardsModal";
import NotFoundPage from "./view/NotFoundPage";
import { useEffect } from "react";

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const displayLogOutBtn = [
    "/home",
    "/rewards",
    "/upload",
    "/submissions",
  ].includes(location.pathname);

  const searchParams = new URLSearchParams(window.location.search);
  const channel = searchParams.get("channel");

  useEffect(() => {
    const uppercaseChannel = channel ? channel.toUpperCase() : null;
    const channels = ["KAREN", "AGNES", "AHCHEN"];
    if (channels.includes(uppercaseChannel)) {
      window.location.href = `${process.env.REACT_APP_REDIRECTION_DOMAIN_URL}/?channel=${uppercaseChannel}`;
    }
  }, []);

  return (
    <div
      className="App"
      style={{
        position: "relative",
      }}
    >
      {/* LOGOUT BTN */}
      {displayLogOutBtn && (
        <div
          style={{
            position: "absolute",
            top: "35px",
            right: "20px",
            zIndex: 1000,
          }}
          onClick={() => {
            dispatch(userLogout());
            navigate("/login");
          }}
        >
          <LogoutBtn />
        </div>
      )}

      <ShowStayTunedHaleonRewardsModalMaybe />

      <Routes>
        <Route exact path="/welcome" element={<LandingPage />} />
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/verify-otp" element={<OtpVerify />} />
        <Route
          exact
          path="/home"
          element={
            <AuthRoute>
              <Home />
            </AuthRoute>
          }
        />
        <Route
          exact
          path="/upload"
          element={
            <AuthRoute>
              <Upload />
            </AuthRoute>
          }
        />
        <Route
          exact
          path="/rewards"
          element={
            <AuthRoute>
              <Rewards />
            </AuthRoute>
          }
        />
        <Route
          exact
          path="/submissions"
          element={
            <AuthRoute>
              <Submissions />
            </AuthRoute>
          }
        />

        <Route path="/404" element={<NotFoundPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export default App;
