import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import "../css/Auth.css";
import primaryBgImg from "../images/login_bg.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import axiosClient from "../utils/axiosClient";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchUserProfileIfNotExist } from "../redux/user/userActions";
import StoreChannelValidateAndRequiredModal from "../components/StoreChannelValidateAndRequiredModal/StoreChannelValidateAndRequiredModal";
import { validatePhoneNumber } from "../utils/validation";
export const Login = () => {
  const dispatch = useDispatch();

  const { userProfile, isLoading: userRLoading } = useSelector(
    (state) => state.userReducer
  );

  const regexPhoneNum = /^([0-9]{8})$/;
  // const [proceed, setProceed] = useState(false);
  const navigate = useNavigate();
  const [errorModal, setErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exist, setExist] = useState(false);
  const [error, setError] = useState();
  const [form] = Form.useForm();

  const routeSearch = window.location.search;
  const userToken = localStorage.getItem("user-token");
  // get user-token from query
  const routeUserToken = new URLSearchParams(routeSearch).get("user-token");

  useEffect(() => {
    if (routeUserToken) {
      localStorage.setItem("user-token", routeUserToken);
      navigate(`/`);
   }
  }, [routeUserToken])
  

  useEffect(() => {
    userToken && dispatch(fetchUserProfileIfNotExist());
  }, [dispatch, userToken]);

  const [showChannelRequiredModal, setShowChannelRequiredModal] =
    useState(false);

  const validateChannel = async (channel) => {
    axiosClient
      .get(`/public/haleon-cny/campaigns/${channel}/validity`)
      .then((res) => {
        if (!res.data?.isValid) {
          setShowChannelRequiredModal(true);
        }
      })
      .catch((err) => {
        setShowChannelRequiredModal(true);
      });
  };

  const [searchParams] = useSearchParams();
  const routeChannel = searchParams.get("channel");

  useEffect(() => {
    if (routeChannel) {
      validateChannel(routeChannel);
    }
  }, []);

  useEffect(() => {
    if (userProfile && !userRLoading) {
      navigate(`/home${window.location.search}`);
    }
  }, [userRLoading, userProfile, navigate, routeSearch]);

  const handleSubmit = async (values) => {
    setLoading(true);
    if (!routeChannel) {
      setShowChannelRequiredModal(true);
      setLoading(false); 
      return;
    }

    try {
      const sendData = {
        phone: "65" + values.phone,
      };

      const res = await axiosClient.post("/auth/check-user", sendData);

      setLoading(false);

      if (res) {
        const data = {
          ...values,
          identity: res.data?.identity,
          // storeId,
        };
        navigate(`/verify-otp${window.location.search}`, { state: data });
      }
    } catch (error) {
      setErrorModal(true);
      setLoading(false);
      setExist(true);
      setError(error.response.data.error);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        backgroundImage: `url(${primaryBgImg})`,
        backgroundSize: "100%",
        backgroundRepeat: "cover",
        backgroundPosition: "top center",
        overflow: "hidden",
        position: "relative",
        // display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <StoreChannelValidateAndRequiredModal
        open={showChannelRequiredModal}
        onCancel={() => setShowChannelRequiredModal(false)}
      />
      <div className="inner-wrap">
        <h1 className="title-font">Log In</h1>
        <hr className="underline-style" />
        <p className="sub-title-font" style={{ marginTop: "5%" }}>
          Key in Phone Number to Login now!
        </p>
        <div>
          <Form
            form={form}
            name="login-form"
            id="login-form"
            onFinish={handleSubmit}
            disabled={loading}
            aria-label="form"
            data-testid="login-form"
            autoComplete="off"
            style={{ textAlign: "center", marginTop: "10%", color: "white" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "left",
              }}
            >
              <div
                style={{
                  borderRadius: "10px",
                  border: "2px solid #DEDEDE",
                  background: "white",
                  width: "13%",
                  height: "35px",
                  fontSize: "12px",
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10%",
                }}
              >
                <span>+65</span>
              </div>
              <Form.Item
                name="phone"
                validateDebounce={500}
                hasFeedback
                className="login-text-color"
                style={{ fontSize: "12px", width: "110%", textAlign: "left" }}
                rules={[
                  {
                    required: true,
                    message: "Phone is required",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && value?.length > 0) {
                        if (regexPhoneNum.test(value)) {
                          return Promise.resolve(true);
                        }
                        return Promise.reject(
                          new Error("Invalid phone number format!")
                        );
                      }
                      return Promise.resolve(true);
                    },
                  }),
                ]}
              >
                <Input
                  type="tel"
                  name="phone"
                  style={{
                    borderRadius: "10px",
                    border: "2px solid #DEDEDE",
                    background: "white",
                    width: "80%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                    height: "40px",
                    position: "relative",
                    right: "3%",
                  }}
                  placeholder="Your Phone Number"
                />
              </Form.Item>
            </div>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  fontWeight: "bold",
                  backgroundColor: "#63BC46",
                  width: "80%",
                  height: "40px",
                  margin: "10% auto",
                  border: "none",
                }}
              >
                SEND OTP CODE
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
