import React, { useEffect, useState } from "react";
import { Button } from "antd";
import "../css/Auth.css";
import OtpInput from "react18-input-otp";
import primaryBgImg from "../images/login_bg.png";
import { useNavigate, useLocation } from "react-router-dom";
import axiosClient from "../utils/axiosClient";
import { useLocalStorage } from "@uidotdev/usehooks";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfileIfNotExist } from "../redux/user/userActions";

export const OtpVerify = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

	const { userProfile, isLoading: userRLoading } = useSelector((state) => state.userReducer);

  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(60);
  const location = useLocation();
  const [error, setError] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [, setTempOtp] = useLocalStorage("tempOtp");
  const [loading, setLoading] = useState(false);

  const inputStyle = {
    border: "none",
    width: "4rem",
    height: "4rem",
    textAlign: "center",
    backgroundColor: "white",
    boxShadow: "none",
    outline: "none",
    color: "black",
    fontSize: "20px",
    fontWeight: "bold",
    borderRadius: "10px",
  };

  const containerStyle = {
    textAlign: "center",
    justifyContent: "center",
    marginTop: "5%",
  };


  useEffect(() => {
    if (timer > 0) {
      const count = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearTimeout(count);
    }
  }, [timer]);

  const routeSearch = window.location.search;
  const userToken = localStorage.getItem("user-token");

  useEffect(() => {
    if (!location.state || !location.state.phone) {
      navigate('/login' + routeSearch);
    }
  }, [location.state, navigate, routeSearch]);

  useEffect(() => {
		userToken && dispatch(fetchUserProfileIfNotExist());
	}, [dispatch, userToken]);
  
  
  useEffect(() => {
    if(userProfile && !userRLoading) {
      navigate(`/home${window.location.search}`
);
    }
  }, [userRLoading, userProfile, navigate, routeSearch])

  const showModal = async () => {
    setLoading(true);

    try {
      const IDENTITY = location.state?.identity;
      const res = await axiosClient.post("/auth/verify-token", {
        token: otp,
        tokenAction: IDENTITY,
        tokenType: "PHONE",
        recipient: "65"+location.state?.phone,
        degenerate: IDENTITY === "REGISTER" ? false : true,
      });
      

      if (res) {
        setMsg("Your phone number has been successfully verified.");
        setIsModalOpen(true);

        if (IDENTITY === "LOGIN") {
          // set temp otp for register
          localStorage.setItem("user-token", res.data.token);
          navigate(`/home${window.location.search}`);
        } else if (IDENTITY === "REGISTER") {
          // set temp otp for register
          setTempOtp(otp);
          setTimeout(() => {
            navigate(`/register${window.location.search}`, { state: location.state });
          }, 3000);
        }
      }

    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
      message.error(error.response.data.message);
    }
  };

  const resendOtp = async () => {
    if (timer !== 0) {
      return;
    }
    setOtp("");

    try {
      const IDENTITY = location.state?.identity;

      const res = await axiosClient.post("/auth/token", {
        recipient: "65"+location?.state.phone,
        tokenAction : IDENTITY,
        tokenType: "PHONE",
      });

      if(res) {
        message.success("new otp code sent successfully");
        setTimer(60);
      } 

    } catch (error) {
      message.error(error?.response.data.message || "Error");
    }
  };


  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        backgroundImage: `url(${primaryBgImg})`,
        backgroundSize: "100%",
        backgroundRepeat: "cover",
        backgroundPosition: "top center",
        overflow: "hidden",
        position: "relative",
        // display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div className="inner-wrap">
        <h1 className="title-font">OTP Verification</h1>
        <hr className="underline-style" />
        <p className="sub-title-font font-14" style={{ marginTop: "5%" }}>
          Please type verification code sent to +65{location.state?.phone}
        </p>

        <OtpInput
          value={otp}
          numInputs={4}
          onChange={(e) => setOtp(e)}
          isInputNum={true}
          separator={<span> &nbsp;&nbsp;&nbsp;&nbsp; </span>}
          containerStyle={containerStyle}
          inputStyle={inputStyle}
        />
        <h1
          style={{
            textAlign: "center",
            fontSize: "25px",
            color: "white",
          }}
        >
          {timer}s
        </h1>
        <p
          className="mt-2 text-center fontSize-14 font-weight-600"
          style={{ color: "white" }}
        >
          Didn’t get OTP?{" "}
          <span
            style={{ color: "#30E700" }}
            className="font-weight-600 font-16"
            // onClick={() =>
            // 	navigate('/signup', { state: { storeId, referId } })
            // }
            onClick={resendOtp}
          >
            {" "}
            Resend OTP{" "}
          </span>
        </p>

        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={showModal}
          style={{
            fontWeight: "bold",
            backgroundColor: otp.length === 4 ? "#63BC46" : "#B1B1B1",
            display: "flex",
            justifyContent: "center",
            width: "87%",
            height: "40px",
            alignItems: "center",
            margin: "15% auto",
            border: "none",
            borderRadius: "5px",
          }}
          className="font-16 font-weight-700"
        >
          VERIFY{" "}
        </Button>
      </div>
    </div>
  );
};

export default OtpVerify;
